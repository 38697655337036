import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NavBar from './/Navbar';
import './../Welcome/Welcome.css';
import Spinner from 'react-bootstrap/Spinner';
import { db, auth } from "./../firebase";
import {
    collection,
    addDoc,
    serverTimestamp,
    getDocs,
    getDoc,
    updateDoc,
    deleteDoc,
    doc,
    onSnapshot 
  } from 'firebase/firestore';


function Images() {

    // Utilisation du hook useParams pour obtenir l'id
    const { id } = useParams();

    const [documentData, setDocumentData] = useState(JSON.parse(localStorage.getItem('documentData')) || null);
    const [isLoading, setIsLoading] = useState(!documentData);  // Montre un loader si les données ne sont pas en cache

    const [error, setError] = useState(null);


    useEffect(() => {
        const docRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/photos', id);  // Remplacer 'items' et 'specificDocumentId' par vos valeurs
        // Écouter les modifications du document en temps réel
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
            const newData = docSnap.data();  // Récupérer les nouvelles données
    
            // Vérifier si les données du localStorage sont différentes des nouvelles données
            const cachedData = JSON.parse(localStorage.getItem('documentData'));
    
            if (JSON.stringify(newData) !== JSON.stringify(cachedData)) {
                // Mettre à jour l'état et le localStorage si les données ont changé
                setDocumentData(newData);
                localStorage.setItem('documentData', JSON.stringify(newData));
            }
    
            setIsLoading(false);  // Arrêter le loader une fois que les données sont récupérées
            } else {
            console.log('No such document!');
            setIsLoading(false);  // Arrêter le loader même si le document n'existe pas
            }
        });
    
        return () => unsubscribe();  // Nettoyer l'écouteur lorsque le composant est démonté
        }, []);

    
    if (isLoading) {
        return <div><><div className="App-header fs-3 fw-normal text-dark p-5">
        <Spinner animation="grow" variant="warning" role="status">
            <span className="visually-hidden text-light">Loading...</span>
        </Spinner>
    </div></> </div>;
    }
    
    if (error) {
        return <div className='fs-6'>Error: {error}</div>;
    }


  return (
    <div className='App'>
        <NavBar />
        <br />  <br />
        <div className='container'>
            <br /> <br />  
            <h4 className='fw-bold'><i className='fa-solid fa-image'></i> Photos/{documentData.title} </h4>
            <br />  
            
            {documentData ? (
            <div>
                <h6 className='text-secondary mb-1' style={{fontWeight: '550'}}>Photos/{documentData.title} </h6>
                <br />
                {documentData.images.map((item, index) => (
                    <>
                        <div className='mb-3'> 
                            <img key={index} src={item} className='apps-image' />
                        </div>
                    </>     
                ))}
            </div>
            ) : (
            <div>No data found</div>
            )}
        </div>   
    </div>
  );
}

export default Images;