import React, { useEffect, useState } from "react";
import NavBarBell from './NavBarBell';
import './tab2.css';
import { Link } from 'react-router-dom';
//import MobileTabs from '../Tabs';
import { db, auth } from "./../firebase";
import { collection, getDocs, onSnapshot  } from "firebase/firestore";


// Composant A
const ComponentA = () => {


  const [data, setData] = useState(JSON.parse(localStorage.getItem('data')) || []);
  const [isLoading, setIsLoading] = useState(!data.length);


  // useEffect pour charger les données une seule fois, ou lors de la modification
  useEffect(() => {
    // Collection Firestore
    const collectionRef = collection(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/photos');
    // Utiliser un écouteur en temps réel pour Firestore
    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      const newData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Vérifier si les données sont différentes de ce qui est déjà stocké dans le localStorage
      if (JSON.stringify(newData) !== JSON.stringify(data)) {
        setData(newData);
        localStorage.setItem('data', JSON.stringify(newData));
        setIsLoading(false);
      }
    });
    // Nettoyage de l'écouteur Firestore
    return () => unsubscribe();
  }, [data]);


  if (isLoading) {
    return <div className="fs-6 fw-normal">Chargement...</div>;
  }


  return( 
    <div>
      {data.map((item, index) => ( 
        <div className=''>
            <Link className='text-selected' to={`/images/${item.id}`}><i className="fa-solid fa-image me-1"></i> {item.title}</Link>
            <hr></hr>
        </div>
      ))}

      <Link to='/albums'><i className="fa fa-image bg-warning floating text-white p-4 fs-3 me-1"></i></Link>
   </div>
  );
};

// Composant B
const ComponentB = () => {
  return <div><h6>Pas de vidéos pour l'instant</h6></div>;
};


const Tab2 = () => {

  // État pour déterminer quel composant afficher
  const [activeComponent, setActiveComponent] = useState('A');

  return (
    <div className='App'>
         <NavBarBell/>
         <br />  <br /> <br />
         <div className="container">
            <br />
            <div className="text-center d-flex box-gallery">
              <div className=''>
                <Link className='text-media text-dark'onClick={() => setActiveComponent('A')}>Photos</Link>
                {activeComponent === 'A' && <div><hr></hr></div> }
              </div>
              <div>
              <Link className='text-media text-dark'onClick={() => setActiveComponent('B')}>Vidéos</Link>
              {activeComponent === 'B' && <div><hr></hr></div> }
              </div>
            </div>

            <div className="container box-selected">
                {/* Affiche le composant en fonction du bouton cliqué */}
                {activeComponent === 'A' && <ComponentA />}
                {activeComponent === 'B' && <ComponentB />}
            </div>
        </div>

    </div>
  )
};

export default Tab2;
