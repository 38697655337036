import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './../../images/logo.png';
import './../Welcome/Welcome.css';
import Button from 'react-bootstrap/Button';
import NavBar from './Navbar';
import {  db } from './../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Importer les méthodes de Storage
import {
    collection,
    addDoc,
    serverTimestamp,
    getDocs,
    getDoc,
    updateDoc,
    deleteDoc,
    onSnapshot,
    doc
  } from 'firebase/firestore';
//import { useForm } from 'react-hook-form';


const FolderI = () => {

    const [message, setMessage]    = useState('');

    const [user, setUsers]         = useState(null);
    const [error, setError]        = useState('');

    //const [image, setImage]        = useState(null);  // Stocke l'image sélectionnée
    const [imageURL, setImageURL]  = useState('');  // Stocke l'URL de l'image une fois téléchargée

    const [title, setTitle]        = useState('');
    const [loading, setLoading]    = useState(true);

    const navigate = useNavigate();


    useEffect(() => {
        const userId =  localStorage.getItem('userId');
        const docRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/users', userId);
      
        // Ecoute en temps réel les modifications du document
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
          if (docSnap.exists()) {
            setImageURL(docSnap.data().cache_image); // Mise à jour de l'image
            if(docSnap.data().admin !== 'admin'){
              navigate('/app'); 
            }
          } else {
            console.log('No such document!');
          }
        });
      
        // Nettoyage de l'écouteur lorsque le composant est démonté
        return () => unsubscribe();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');  
        try {
          await addDoc(collection(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/photos'), { title: title, PublicationtDate: serverTimestamp()   });
          setMessage('Album crée !');
          setTitle(''); // Clear the input field after successful submission
        } catch (error) {
          setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className='App'>
            <NavBar />
            <br />  <br />
            <div className='container'>
                <br /> <br />  
                <h4 className='fw-bold'>Nouvel album </h4>
                <br />  
                <form onSubmit={handleSubmit}> 
                    <div className='mb-3'>
                        <label>Nom de l'album</label>
                        <input className='form-control' required value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <button type='submit' className='btn btn-warning mt-2 btn-lg text-light fw-bold' style={{ width: '100%'}}>
                        Créer <i className='fa-solid fa-plus-circle ms-2'></i>
                    </button>
                </form>
            </div>
            <div className='ps-3 pe-3 mt-2'>
            {message && 
                <p className='fs-6 text-success text-center'>{message}</p>
            }
            </div> 
        </div>
    )
};

export default FolderI;
