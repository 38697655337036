import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarProfile from './NavBarProfile';
import  './profile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'; 
import MobileTabs from './../Tabs';
import Spinner from 'react-bootstrap/Spinner';
import {  db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';



const Tab3 = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const logout = async (e) => {
        try {
          const userId = 'VdaEMM8hrHaAcwGSuuCibn4bZ233';
          const documentRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/users', userId);
          await updateDoc(documentRef, {
            logout: true, logged: false  // Remplace 'fieldName' par le champ à mettre à jour
          });
          //console.log("Document mis à jour avec succès !");
        } catch (error) {
          console.error("Erreur lors de la mise à jour du document : ", error);
        }
    };

    const logout001 = async (e) => {
        //e.preventDefault();
        // remove
        localStorage.removeItem('my-key');
        // remove all
        localStorage.clear();
        navigate('/');
        window.location.reload(false);  
    }


    return (   
        <div className='App'>
        <NavBarProfile />
        <br /> <br />
        <div className="container">
            <div className=''>
                <br />  <br />
                <h4 className='text-dark fw-bold mb-3'>Origines de Bandja</h4>
                {loading && 
                <div>
                    <>
                    <div className="fs-3 fw-normal text-dark p-5">
                    <Spinner animation="grow" variant="warning" role="status">
                        <span className="visually-hidden text-light">Loading...</span>
                    </Spinner>
                    </div>
                    </> 
                </div>
                }
                <img 
                  onLoad={() => setLoading(false)} 
                  style={{ display: loading ? 'none' : 'block' }}
                  src='https://actucameroun.com/wp-content/uploads/2021/02/sa-majestexx-tchoupe-david-bandja.png' 
                  className='apps-image' />
            </div> 
            <div className=''>
                <br />    
                <h5 className='text-dark fw-bold mb-2'>gveiqos feusibr uilbuiqer bvfuisgv usbld de Bandja</h5>
                <h6 className='content-history'>Origine zekblfer erfuirbgeiu gveiqos feusibr uilbuiqer bvfuisgv usbld de Bandja</h6>
                
                <br />
                <button className='logout' onClick={logout}>Log out <i className='fa-solid fa-power'></i> </button>
                
            </div> 
        </div>
      
    </div>
    
    )
};

export default Tab3;
