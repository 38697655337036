// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate  } from 'react-router-dom';
import ConfirmAuth from './components/ConfirmAuth';
import Tab1 from './components/Tab1/index';
import Tab2 from './components/Tab2/index';
import Tab3 from './components/Tab3/index';
import Publication from './components/Publication/index';
import FolderI from './components/Media/FolderI';
import Images from './components/Media/Images';
import MobileTabs from './components/Tabs/index';
import AppLauncher from './components/AppLauncher';
import Redirect from './components/Redirect';
import PageNotFound from "./components/Error/PageNotFound";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Welcome from './components/Welcome';

import {  db } from './components/firebase';
import {  doc, onSnapshot  } from 'firebase/firestore';

import RealTimeRedirect  from './components/Config/RealTimeRedirect';
import ProtectedRoute from './components/Config/ProtectedRoute';
import UnAuth from './components/Config/UnAuth';

import DelayedRoute from './components/Config/DelayedRoute'; // Importez DelayedRoute


function App() {

  { /* const [user] = useAuthState(auth);  */}

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Redirection à partir de la racine */}
          <Route path="/" element={<Redirect />} />

          {/* Authentification publique */}
          <Route path="/auth" element={<ConfirmAuth />} />
          <Route path="/un" element={<UnAuth />} />

          {/* Routes protégées */}
          <Route 
            path="/app" 
            element={
              <ProtectedRoute>
                <AppLauncher />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/albums" 
            element={
              <ProtectedRoute>
                <FolderI />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/images/:id" 
            element={
              <ProtectedRoute>
                <Images />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/publication" 
            element={
              <ProtectedRoute>
                <Publication />
              </ProtectedRoute>
            } 
          />

          {/* Page non trouvée */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  );

}

export default App;
