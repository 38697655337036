import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Tab1 from './Tab1/index';
import Tab2 from './Tab2/index';
import Tab3 from './Tab3/index';
import MobileTabs from './Tabs/index';
import { Link } from 'react-router-dom';


const AppLauncher = () => {

    const [activeComponent, setActiveComponent] = useState('A');

    // getter
    const key = localStorage.getItem('my-key');
    //const [show, setShow] = useState(true);

    useEffect(() => {
       localStorage.setItem('from', 'app'); 
       const BackFrom = localStorage.getItem('from');
       if(BackFrom === 'images') {setActiveComponent('B');} // affiche tab2 si vient de la pages images/   
       localStorage.setItem('from', 'app'); 
    }, [key])

    const handleClose = async (e) => {
        //setShow(false);
        //navigate('/app');
    }

    
    return (

        <div className='App'>
            {activeComponent === 'A' && <Tab1 />}
            {activeComponent === 'B' && <Tab2 />}
            {activeComponent === 'C' && <Tab3 />}

            <footer className="mobile-tabs">
                <Link className='' onClick={() => setActiveComponent('A')}>
                    {activeComponent === 'A' && <i className="fa-solid fa-home icon-footer active pt-3 pb-3 fs-3"></i> }
                    {activeComponent !== 'A' && <i className="fa-solid fa-home icon-footer pt-3 pb-3 fs-3" style={{ color: '#727171'}}></i> }
                    <br />
                </Link>
            
                <Link className='' onClick={() => setActiveComponent('B')}>
                    {activeComponent === 'B' && <i className="fa-solid fa-photo-film icon-footer active pt-3 pb-3 fs-3"></i> }
                    {activeComponent !== 'B' && <i className="fa-solid fa-photo-film icon-footer pt-3 pb-3 fs-3" style={{ color: '#727171'}}></i> }
                    <br />
                </Link>

                <Link className='' onClick={() => setActiveComponent('C')}>
                    {activeComponent === 'C' && <i className="fa-solid fa-clock-rotate-left icon-footer active focus-color pt-3 pb-3 fs-3"></i> }
                    {activeComponent !== 'C' && <i className="fa-solid fa-clock-rotate-left icon-footer pt-3 pb-3 fs-3" style={{ color: '#727171'}}></i> }
                    <br />
                </Link>
            </footer> 
        </div>
         

    )
};

export default AppLauncher;
