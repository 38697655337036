import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './../../images/logo.png';
import imageDefault1 from './../../images/imageDefault.png';
import './../Welcome/Welcome.css';
import Button from 'react-bootstrap/Button';
import NavBarPublication from './NavbarPublication';
import {  db } from './../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Importer les méthodes de Storage
import {
    collection,
    addDoc,
    serverTimestamp,
    getDocs,
    getDoc,
    updateDoc,
    deleteDoc,
    doc,
    onSnapshot  
  } from 'firebase/firestore';
//import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


const Index = () => {

    const [message, setMessage]    = useState('');
    const navigate = useNavigate();

    const [user, setUsers]         = useState(null);
    const [error, setError]        = useState('');

    //const [image, setImage]        = useState(null);  // Stocke l'image sélectionnée
    //const [imageURL, setImageURL]  = useState('');  // Stocke l'URL de l'image une fois téléchargée
    const [imageURL, setImageURL] = useState(null);
    const imageDefault = './../../images/imageDefault.png';

    const [title, setTitle]        = useState('');
    const [subtitle, setSubtitle]  = useState('');
    const [content, setContent]    = useState('');

    const [loading, setLoading]    = useState(true);


    useEffect(() => {
      const userId = 'VdaEMM8hrHaAcwGSuuCibn4bZ233';
      const docRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/users', userId);
    
      // Ecoute en temps réel les modifications du document
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setImageURL(docSnap.data().cache_image); // Mise à jour de l'image
          if(docSnap.data().admin !== 'admin'){
            navigate('/app'); 
          }
        } else {
          console.log('No such document!');
        }
      });
    
      // Nettoyage de l'écouteur lorsque le composant est démonté
      return () => unsubscribe();
    }, []);
    

    const handleUpdate = async (e) => {
      e.preventDefault();
      setMessage('');  
      const now = new Date();
      const formattedDateTime = now.toLocaleString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
      });
      const docRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/publications', 'Dq2IH9qonPF54ryNaZPM');
      await updateDoc(docRef, {
        images: imageURL, title: title, subtitle: subtitle, content: content, PublicationtDate: formattedDateTime
      });
      toast.success('Document updated successfully');
      setMessage('Publication éffectuée!');
      setTitle(''); // Clear the input field after successful submission
      setSubtitle('');
      setContent('');
    };


    const pickImage = async (e) => {
      try {
        const userId = 'VdaEMM8hrHaAcwGSuuCibn4bZ233';
        const documentRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/users', userId);
        await updateDoc(documentRef, {
          images_manager: true,  // Remplace 'fieldName' par le champ à mettre à jour
        });
        //console.log("Document mis à jour avec succès !");
      } catch (error) {
        console.error("Erreur lors de la mise à jour du document : ", error);
      }
    };


    return (
        <div className='App'>
            <NavBarPublication />
            <br />  <br />
            <div className='container'>
                <br /> <br />  
                <h4  className='fw-bold'>Nouvelle publication </h4>
                <br />  
                <div className='mb-3 d-flex'>
                  <img
                    src={imageURL ? imageURL : imageDefault1}
                    className='me-2'
                    style={{ width: '15%', borderRadius: '5px' }}
                    alt="logo"
                  />
                  <Link onClick={pickImage} className='mt-4' style={{width: '35%', borderRadius: '5px'}}> <i className='fas fa-plus-circle text-warning fs-3'></i> </Link>
                  {/* <img src={imageURL} className='me-2 bg-dark' style={{width: '15%', borderRadius: '5px'}} alt="logo" /> */}
                </div>
                <form onSubmit={handleUpdate}>                
                    <div className='mb-3'>
                        <label>Titre</label>
                        <input className='form-control' required value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className='mb-3'>
                        <label>Sous titre </label>
                        <input className='form-control' required value={subtitle} onChange={(e) => setSubtitle(e.target.value)} /> 
                    </div>
                    <div className='mb-3'>
                        <label>Contenu</label>
                        <textarea className='form-control fs-6' required value={content} onChange={(e) => setContent(e.target.value)} rows='9' > </textarea>
                    </div>
                    <button type='submit' className='btn btn-warning mt-2 btn-lg text-light fw-bold' style={{ width: '100%'}}>
                        Publier <i className='fa-solid fa-upload ms-2'></i>
                    </button>
                </form>
            </div>
            <div className='ps-3 pe-3'>
            {message && 
                <p className='fs-6 text-success text-center'>{message} <i className="fa-regular fa-face-smile"></i></p>
            }
            </div> 
        </div>
    )
};

export default Index;
