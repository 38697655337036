import React, { useEffect, useState } from "react";
import './Home.css';
import NavBarHome from './NavBarHome';
//import MobileTabs from './../Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { db, auth } from "./../firebase";
import { collection, getDocs, onSnapshot, doc } from "firebase/firestore";
import Spinner from 'react-bootstrap/Spinner';



const Tab1 = () => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [documentData, setDocumentData] = useState(JSON.parse(localStorage.getItem('documentPub')) || null);
  const [isLoading, setIsLoading] = useState(!documentData);  // Montre un loader si les données ne sont pas en cache

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const docRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/publications', 'Dq2IH9qonPF54ryNaZPM');  // Remplacer 'items' et 'specificDocumentId' par vos valeurs
    // Écouter les modifications du document en temps réel
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
        const newData = docSnap.data();  // Récupérer les nouvelles données
        // Vérifier si les données du localStorage sont différentes des nouvelles données
        const cachedData = JSON.parse(localStorage.getItem('documentPub'));

        if (JSON.stringify(newData) !== JSON.stringify(cachedData)) {
            // Mettre à jour l'état et le localStorage si les données ont changé
            setDocumentData(newData);
            localStorage.setItem('documentPub', JSON.stringify(newData));
        }
        setIsLoading(false);  // Arrêter le loader une fois que les données sont récupérées
        } else {
        console.log('No such document!');
        setIsLoading(false);  // Arrêter le loader même si le document n'existe pas
        }
    });

    return () => unsubscribe();  // Nettoyer l'écouteur lorsque le composant est démonté
  }, []);


  if (isLoading) {
      return <div><><div className="App-header fs-3 fw-normal text-dark p-5">
      <Spinner animation="grow" variant="warning" role="status">
          <span className="visually-hidden text-light">Loading...</span>
      </Spinner>
  </div></> </div>;
  }


  return (

    <div className='App'>
        <NavBarHome />
        <br />
          <div className='col-md-12 mt-4  ' style={{ overflowY: 'hidden'}}>
            <div className='' style={{ overflowY: 'hidden'}}>
               <div className='overlay'>
                <>
                 {documentData ? (
                  <>
                  <div class="text-dark">
                    <h5 className='text-white fw-bold pt-2'> {documentData.title} </h5>
                    <h6 className='text-tab1' style={{ whiteSpace: 'pre-wrap', marginTop: '7px' }}>{documentData.content}</h6>
                    <h6 className="fw-normal text-light" style={{ fontSize: '11px' }} >Publié le  { documentData.PublicationtDate }</h6>
                  </div>
                  </>
                 ) : (
                  <div>No data found</div>
                 )}
                </>
               </div>
            </div>
          </div>    
    </div>
  )
};

export default Tab1;
