import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore'; // Assurez-vous d'importer onSnapshot et doc
import { Navigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


const ProtectedRoute = ({ children }) => {
  
  const [isLoading, setIsLoading] = useState(true); // Pour gérer l'état de chargement
  const [isLogged, setIsLogged] = useState(''); // Pour gérer l'état de chargement

  const navigate = useNavigate();

  // setter test
  // Récupérer 'my-key' depuis localStorage

    //localStorage.setItem('my-key', 'VdaEMM8hrHaAcwGSuuCibn4bZ233');
    const       key = localStorage.getItem('my-key');
  
    if (!key) {
        alert('No key found in localStorage   111');
        navigate('/un')
        setIsLoading(false); // Permet de terminer le chargement si la clé n'existe pas
    } 

    
    useEffect(() => {

        const    k = localStorage.getItem('my-key');
        
        const docRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/users', key);
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setIsLogged(data.logged);
                setIsLoading(false);

                if (data.logged === false) {
                    navigate('/auth');
                }
            } else {
                alert('No such document!');
                navigate('/un');
                setIsLoading(false);
            }
        });

        return () => unsubscribe();
    }, [navigate]);


   // Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
   if (isLoading) {
    return <div>
        <><div className="App-header fs-3 fw-normal text-dark p-5">
            <Spinner animation="grow" variant="warning" role="status">
                <span className="visually-hidden text-light">Loading...</span>
            </Spinner>
        </div></> 
    </div>;
    
  }

  return isLogged ? children : null; // Return children only if logged
  //return children; // Sinon, on affiche le contenu protégé

};

export default ProtectedRoute; // Export par défaut
