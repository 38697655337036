import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Tab1 from './Tab1/index';
import Tab2 from './Tab2/index';
import Tab3 from './Tab3/index';
import MobileTabs from './Tabs/index';
import { Link } from 'react-router-dom';
import {  db } from './firebase';
import {  doc, onSnapshot  } from 'firebase/firestore';


const Redirect = () => {

    const navigate = useNavigate();
    const [userID, setUserID] = useState(null);


    useEffect(() => {
      // fonction pour récupérerles données du localstorage
        const handleStorageChange = () => {
          const storedData = localStorage.getItem('userId');
          if (storedData) {
            // Met à jour l'état avec la nouvelle valeur de userID
            setUserID(storedData);
            localStorage.setItem('my-key', storedData);
            //alert('Hello key  001' + localStorage.getItem('my-key'));
            navigate('/app'); // Redirection immédiate
          }
        };
    
        // vérifier si les données sont présentes lors du premier chargement 
        handleStorageChange();

        //Ajouter un écouteur d'évènement sur le storage
        window.addEventListener('storage', handleStorageChange);

        // Nettoyage de l'écouteur pour éviter les fuites de mémoire
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
    }, []);  // le tableau vide [] indique que l'effet ne se produit qu'au chargement initial


    return (
        <div className='App'>
            <div className='App-header'>
               
            </div>
        </div>
    )
};

export default Redirect;
