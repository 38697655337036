import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from './../images/logo.png';
import Tab1 from './Tab1/index';
import Tab2 from './Tab2/index';
import Tab3 from './Tab3/index';
import MobileTabs from './Tabs/index';
import { Link } from 'react-router-dom';
import {  db } from './firebase';
import {  doc, updateDoc, onSnapshot  } from 'firebase/firestore';


const ConfirmAuth = () => {

    const navigate = useNavigate();

    const [show, setShow] = useState(true);

    const handleClose = async (e) => {
        try {
            const userId      = localStorage.getItem('my-key');
            const documentRef = doc(db, 'entreprises/WHsQQDGqPAWizwXL3EAg/users', userId);
            await updateDoc(documentRef, {
              logged: true,  // Remplace 'fieldName' par le champ à mettre à jour
            });
            navigate('/app');
          } catch (error) {
            console.error("Erreur lors de la mise à jour du document : ", error);
        }
        setShow(false);
        
    }


    return (
        <div className='App'>
            <div className='App-header'>
                <div className='p-4 text-center'>
                    <br />
                    <img className='App-logo' src={logo}  alt="Logo" />
                    <h2 className='Brand'>Chefferie Bandja </h2>
                    <span className='desc'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Mauris condimentum ante velit, quis eleifend urna ultricies et. 
                            Vivamus consectetur aliquet ultricies.
                    </span>
                    <button className='go' onClick={handleClose}>
                       I am Understood
                    </button>
                </div>
                
            </div>
        </div>
    )
};

export default ConfirmAuth;
